import { QueryClient } from "@tanstack/vue-query"
import { useProjectApi } from "../api/useProjectApi"
import QueryKeys from "~/vue-query/QueryKeys"
import { DeploymentInfo } from "~/api/contracts/models"

export function useProjectDeploymentInfosQueryPrefetch(
    queryClient: QueryClient,
    projectId: Ref<string>
 ): Promise<void> {
    const client = useProjectApi()
    
    return queryClient.prefetchQuery<Record<string, DeploymentInfo[]>>(
       [QueryKeys.ProjectDeploymentInfos, projectId],
       () => client.prefetchDeploymentInfos(projectId.value))
 }
 